import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { specialDaysPage } }, location }) {

	return (
		<>
			<SEO title="Special Days" description={ specialDaysPage.seoDescription } pathname={ location.pathname } />

			{ specialDaysPage && specialDaysPage.content &&
				<DynamicZone components={ specialDaysPage.content } />
			}
		</>
	)
}

export const query = graphql`
	query speicalDayPageQuery {
		strapi {
			specialDaysPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentGeneralHeroBanner {
						id
						heroDescription
						heroTitle
						imageFocus
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000, maxHeight: 500) {
										...GatsbyImageSharpFluid_noBase64
							 		}
								}
							}
						}
					}
					... on StrapiQuery_ComponentSpecialSpecialDayPage {
						id
						title
						description
						duration
						price
						availability
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750, maxHeight: 750) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
				}
			}
		}
	}
`